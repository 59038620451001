.input-group {
  border: 1px solid lightgrey !important;
  border-radius: 20px !important;
  width: 8rem;
  overflow: hidden;
}

.input-group button:active {
  background-color: white;
}

#quantityInput {
  border: none !important;
}
