@import url("https://fonts.googleapis.com/css2?family=Anta&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

* {
  font-family: "Nanum Gothic", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: black;
}
html {
  scroll-behavior: smooth;
}

button {
  border-radius: 0 !important;
}
