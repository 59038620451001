.cart-container {
  position: fixed;
  top: 0;
  right: -100%; /* Start off-screen */
  width: 45vh; /* Adjust as needed */
  height: 100%;
  background-color: white;
  transition: right 0.3s ease-in-out;
  z-index: 1000;
  max-height: 100vh;
  overflow-y: auto;
}

.cart-container.open {
  right: 0;
}

.cart-container h1 {
  text-align: center;
}

.product-detail h5 {
  font-size: 1.8vh;
}
.product-detail p {
  margin: 0;
  padding: 0;
  color: darkgray;
  font-size: 14px;
}

.product-detail h5 {
  margin: 0;
  padding: 0;
}

.quantity-input {
  display: flex;
  align-items: center;
}

.quantity-btn {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
}

.quantity-input-field {
  width: 60px;
  text-align: center;
}

.cut-icon {
  cursor: pointer;
}

/* Add any other styling you need for the cart items, buttons, etc. */

@media (max-width: 576px) {
  .cart-container.open {
    width: 100%;
  }
}
