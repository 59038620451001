.navLink {
  padding: 1rem;
  max-width: 150px;
  color: black !important;
  transition: background-color 0.3s ease, border-radius 0.3s ease !important;
}

.navLink:hover {
  background-color: black;
  border-radius: 15px;
  color: white !important;
}

.navLink.active {
  text-decoration: underline;
}

#basic-navbar-nav {
  transition-duration: 1s ease-in-out;
}

.search-button {
  cursor: pointer;
}

.navbar {
  width: 100%;
  transition: transform 0.3s ease-in-out;
}

.navbar.scrolled {
  transform: translateY(0);
}

.navbar.hidden {
  transform: translateY(-100%);
}

.navbar-toggler {
  display: contents;
}
