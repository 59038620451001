li {
  list-style-type: none;
  padding: 1px;
}

li:hover {
  text-decoration: underline;
}

li b {
  font-weight: 300;
}

.nav-link {
  padding: 5px;
}

.nav-link span b {
  font-weight: 300;
}

.icon {
  font-size: 20px;
  margin-right: 5px;
}

.footer {
  border-top: 1px solid rgb(151, 150, 150);
  /* background: #f8f9fa; */
}

b:hover {
  text-decoration: underline;
}

.account-col a {
  cursor: pointer;
}

.account-col li {
  margin-bottom: 1vh;
}
